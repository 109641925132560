import React, { Component } from "react";
import PropTypes from "prop-types";
import Tags from "./Tags";

export default class Offerings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
    };
  }
  tagClick = (e) => {
    const tag = e.target.dataset.value;
    const newFilter = this.state.filter === tag ? "" : tag;
    this.setState({
      filter: newFilter,
    });
  };
  renderGallery = () => {
    const { gridItems } = this.props;

    if (!gridItems) {
      return;
    }

    const filter = this.state.filter;
    const gallery = this.props.gridItems
      .filter((product) => {
        if (!this.state.filter) {
          return true;
        }
        return product.type === filter;
      })
      .map((item) => {
        return (
          <div
            key={item.image}
            className="column is-12-mobile is-one-third-tablet"
            style={{ borderRadius: "5px" }}
          >
            <section className="section offering--section why-us">
              <img
                className={`${
                  this.props.isProducts ? "square-product-image" : ""
                }`}
                alt=""
                src={item.image}
              />
              <h3 className="has-text-centered">{item.title}</h3>
              <p className="has-text-centered">{item.text}</p>
            </section>
          </div>
        );
      });

    return gallery;
  };

  setTagVisibility = () => {
    if (this.props.isProducts) {
      return <Tags filter={this.state.filter} tagClick={this.tagClick} />;
    }
    return null;
  };
  render() {
    return (
      <>
        {this.setTagVisibility()}
        <div className="columns is-multiline">{this.renderGallery()}</div>
      </>
    );
  }
}

Offerings.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      text: PropTypes.string,
      type: PropTypes.string,
    })
  ),
};
