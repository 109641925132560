import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import LocalizedLink from "../LocalizedLink";
import Offerings from "../Offerings";
import SEO from "../SEO";
import { RichMessage } from "../RichMessage";

const ProductsPageTemplate = ({ offerings }) => {
  const intl = useIntl();
  return (
    <>
      <SEO
        title={intl.formatMessage({ id: "product_page_title" })}
        description={intl.formatMessage({ id: "product_page_description" })}
      />
      <section className="section section--gradient products">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-8 is-offset-2">
                <div className="content">
                  <div className="products-header">
                    <h3 className="has-text-weight-semibold is-size-2">
                      <FormattedMessage id="Products" />
                    </h3>
                  </div>
                  <LocalizedLink to="/contact">
                    <FormattedMessage id="pricingInfo" />
                  </LocalizedLink>
                  <Offerings isProducts={true} gridItems={offerings.blurbs} />
                  <h1>
                    <FormattedMessage id="product_page_h1" />
                  </h1>
                  <p>
                    <RichMessage id="product_page_p1" />
                  </p>

                  <h2>
                    <FormattedMessage id="upgrade_h2" />
                  </h2>
                  <p>
                    <RichMessage id="upgrade_description" />
                  </p>

                  <h3>
                    <FormattedMessage id="quartz" />{" "}
                    <FormattedMessage id="countertops" />
                  </h3>
                  <p>
                    <RichMessage id="quartz_description" />
                  </p>
                  <h3>
                    <FormattedMessage id="granite" />{" "}
                    <FormattedMessage id="countertops" />
                  </h3>
                  <p>
                    <RichMessage id="granite_description" />
                  </p>
                  <h3>
                    <FormattedMessage id="marble" />{" "}
                    <FormattedMessage id="countertops" />
                  </h3>
                  <p>
                    <RichMessage id="marble_description" />
                  </p>
                  <h3>
                    <FormattedMessage id="quartzite" />{" "}
                    <FormattedMessage id="countertops" />
                  </h3>
                  <p>
                    <RichMessage id="quartzite_description" />
                  </p>
                  <p>
                    <RichMessage id="picking_a_stone" />
                  </p>

                  <h2>
                    <FormattedMessage id="custom_fabrications" />
                  </h2>
                  <p>
                    <RichMessage id="custom_fabrications_description" />
                  </p>

                  <h2>
                    <FormattedMessage id="installation_services" />
                  </h2>
                  <p>
                    <RichMessage id="installation_services_description" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

ProductsPageTemplate.propTypes = {
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

export default ProductsPageTemplate;
