import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ProductsPageTemplate from "../components/ProductsPageTemplate";
import Layout from "../components/Layout";

const ProductsPage = ({ data, path }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout path={path}>
      <ProductsPageTemplate
        title={frontmatter.title}
        meta_title={frontmatter.meta_title}
        meta_description={frontmatter.meta_description}
        heading={frontmatter.heading}
        description={frontmatter.description}
        offerings={frontmatter.offerings}
      />
    </Layout>
  );
};

ProductsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
  path: PropTypes.string,
};

export default ProductsPage;

export const pageQuery = graphql`
  query ProductsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        meta_title
        meta_description
        heading
        offerings {
          blurbs {
            image
            title
            text
            type
          }
        }
      }
    }
  }
`;
