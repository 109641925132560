import PropTypes from "prop-types";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

export default class Tags extends Component {
  constructor() {
    super();

    this.state = {
      tags: ["granite", "marble", "quartz", "quartzite"],
    };
  }
  render() {
    const buttons = this.state.tags.map((tag) => {
      return (
        <button
          className={`button is-rounded ${
            this.props.filter === tag ? "is-primary" : ""
          }`}
          key={tag}
          data-selected={this.props.filter === tag}
          onClick={this.props.tagClick}
          data-value={tag}
        >
          <FormattedMessage id={tag} />
        </button>
      );
    });
    return (
      <div className="container notification">
        <div className="content filters has-text-centered">
          <span className="subtitle mid-align">
            <FormattedMessage id="filterBy" />
          </span>
          <button
            className={`button is-rounded ${
              this.props.filter === "" ? "is-primary" : ""
            }`}
            data-value=""
            data-selected={this.props.filter === ""}
            onClick={this.props.tagClick}
          >
            <FormattedMessage id="showAll" />
          </button>
          {buttons}
        </div>
      </div>
    );
  }
}

Tags.propTypes = {
  filter: PropTypes.any,
  tagClick: PropTypes.any,
};
